:root {
  --grey: #f5f5f5;
  --lightGrey: #fafafa;
  --green: #4caf50;
  --red: #f44336;
  --white: #ffffff;
  --darkRed: #c62828;
  --darkGreen: #2e7d32;
  --greenSeyband: #0a7c35;
  --darkgrey: #dcdcdc;
  --blue: #2283bf;
  --darkblue: #1d6b9b;
  --spangrey: #9b9b9b;
  --redBtn: #891414;
  --darkRedBtn: #6d1010;
  --yellow: #fbc02d;
  --darkYellow: #f9a825;
  --darkDarkYellow: #e3900a;
}

body {
  box-sizing: border-box;
  background-color: var(--white);
}

a {
  color: black;
  text-decoration: none;
}

section {
  padding: 40px;
  padding-bottom: 100px;
}

#directionWork,
#insertEvaluation,
#deliveryNotesBills {
  padding-bottom: 40px !important;
}

#insertEvaluation,
#insertHoursReporting,
#insertItem,
#insertProducts,
#insertbv {
  border-top: 5px solid var(--greenSeyband);
  background: var(--grey);
}

#insertCustomer,
#insertEmployees,
#insertEvaluation,
#insertSupplier {
  border-top: 5px solid var(--blue);
  background: var(--grey);
}

#baseData,
#directionWork,
#directionWorkMain,
#deliveryNotesBills,
#offerCreate,
#offerbook,
#specification,
#construction-diary {
  border-top: 5px solid var(--yellow);
  background: var(--grey);
}

#offerCreate {
  z-index: 1;
  position: relative;
}

#insertCalcHourlyWage,
#sqlquerys,
#workerQuerys,
#qrCode {
  border-top: 5px solid var(--redBtn);
  background: var(--grey);
}

#insertCalcHourlyWage .btn-calcNew,
#insertCalcHourlyWage .btn-create-item,
#insertCalcHourlyWage .wrapper-btn-text a,
#sqlquerys .wrapper-btn-text a,
#workerQuerys .wrapper-btn-text a,
#qrCode .wrapper-btn-text a {
  background: var(--redBtn);
}
.margin-workerQuerys {
  margin: 50px 0;
}
.border-bottom-workerQuerys {
  border-bottom: 1px solid #000;
}
#sectionGrey {
  background: var(--grey);
  padding-top: 0;
  margin-top: -80px;
}
.row {
  width: 100%;
}

.btn-calcNew {
  width: 60px;
  height: 60px;
  margin-right: -70px;
  margin-left: 10px;
  color: #fff;
  border: none;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.btn-calcNew:hover {
  background: var(--darkRedBtn) !important;
  cursor: pointer;
}

.btn-save-directionWork,
.btn-save-product,
.btn-save-product-span,
.btn-search {
  width: 100%;
  height: 60px;
  color: #fff;
  border: none;
  box-shadow: none;
  font-size: 1.3em;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  cursor: pointer;
}
.btn-save-directionWork,
.btn-download-regie{
  font-size: 1.1em !important;
}
.btn-save-product-span{
display: flex;
  text-align: center;
  height: 80px;
  justify-content: center;
   align-items: center;
   box-shadow: inset 0 0 15px #ce9300;
}
#insertCalcHourlyWage .btn-create-item:hover {
  background: var(--darkRedBtn);
  cursor: pointer;
}

#insertCustomer .btn-add-item,
#insertCustomer .wrapper-btn-text a,
#insertEmployees .btn-create-item,
#insertEmployees .wrapper-btn-text a,
#insertEvaluation .wrapper-btn-text a,
#insertSupplier .wrapper-btn-text a {
  background: var(--blue);
}

#baseData .wrapper-btn-text a,
#directionWork .btn-create-item,
#directionWork .wrapper-btn-text a,
#directionWorkList .btn-add-item,
#directionWorkList .btn-create-item,
#directionWorkList .wrapper-btn-text a,
#directionWorkReport .btn-add-item,
#directionWorkReport .btn-create-item,
#directionWorkReport .wrapper-btn-text a,
#deliveryNotesBills .wrapper-btn-text a,
#offerbook .wrapper-btn-text a,
#specification .wrapper-btn-text a,
.btn-save-directionWork,
.btn-save-product,
.btn-save-product-span,
#specification .btn-add-item,
#specification .btn-search,
#construction-diary .btn-create-item {
  background: var(--yellow);
}

#directionWorkList .btn-add-item:hover,
#directionWorkList .btn-create-item:hover,
#directionWorkReport .btn-add-item:hover,
#directionWorkReport .btn-create-item:hover,
.btn-save-directionWork:hover,
.btn-save-product:hover,
#specification .btn-add-item:hover,
#specification .btn-search:hover,
#construction-diary .btn-create-item:hover {
  background: var(--darkYellow);
  cursor: pointer;
}

#directionWorkList .btn-add-item,
#directionWorkReport .btn-add-item {
  margin-top: 0;
}

#directionWorkList .btn-add-blue,
#directionWorkReport .btn-add-blue,
#directionWorkReport .btn-remove,
#insertCustomer .btn-remove,
#insertEmployees .btn-remove,
#insertSupplier .btn-remove,
#showEvaluations .btn-add-blue,
#specification .btn-remove {
  margin-top: 0;
}

#directionWorkReport textarea {
  border: 1px solid #d8d8d8;
}

/* #insertHoursReporting .btn-start{ background: var(--blue); } */
#insertItem .col-1:not(.billsinputformtop .col-1) {
  text-align: right;
}

fieldset {
  padding: 0;
  border: none;
}

.wrapper-btn-text a {
  display: block;
  position: relative;
  margin: 40px 0;
  color: var(--white);
  background: var(--greenSeyband);
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  line-height: 3.6em;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
 

#directionWorkMain .wrapper-btn-text a,
#offerCreate .wrapper-btn-text a,
#construction-diary .wrapper-btn-text a {
  background: var(--yellow);
}

.wrapper-start {
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 1fr 1fr 1fr;
}

.wrapper-modal-button {
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 1fr 1fr;
  /* display: inline-grid; */
}

.item1 {
  text-align: right;
}

.bv-btn,
.item-btn {
  padding: 0 5px;
  grid-column-end: -1;
}

h1 {
  margin: 40px 0;
  text-align: center;
  font-size: 3em;
}

h3 {
  margin: 10px 0 10px;
}

h4 {
  text-align: center;
  text-transform: uppercase;
  font-size: 1.3em;
}

.baseData h4 {
  width: 600px;
  border-bottom: 1px solid #000;
  text-align: left;
}

p {
  margin: 0;
}

ul {
  padding-left: 0;
}

img {
  display: block;
  width: 250px;
  margin: 0 auto;
}

.modal-body input,
.modal-body select {
  height: 55px;
  padding: 10px 10px 0 10px;
  border: 1px solid #ddd !important;
  border: none;
  font-size: 1em;
}
input,
select {
  width: 100%;
  height: 60px;
  padding: 10px 10px;
  border: none;
  font-size: 1em;
}
.smaller-input input{
  height: 50px;
  padding: 10px 10px 0 10px;
}
.HoursList {
  margin-top: 20px;
}
.HoursList input[type="checkbox"] {
  height: 20px;
}
.HoursList hr {
  margin: 5px 0;
}
.workHours-list-checkbox .row {
  margin-bottom: 0;
}
.workHours-list-checkbox input {
  margin-top: 0px;
}
.HoursList h4 {
  margin: 0;
}
input:focus,
select:focus,
textarea:focus {
  box-shadow: 0px 0px 8px 0px #ccc !important;
}
.width-check {
  max-width: 60px;
}
#font-normal input:focus {
  box-shadow: none !important;
}

li p {
  padding: 10px 10px;
  font-size: 0.9em;
}

label {
  display: block;
  padding: 25px 0px 20px 10px;
  text-align: center;
}

.modal-body label {
  display: block;
  padding: 22px 0px 10px 10px !important;
  text-align: center;
}

.bvs-list li p:nth-child(1) {
  text-align: left;
}

.notes-list .row {
  margin-bottom: 0;
}
.border-bottom-notes,
.border-bottom-bills {
  border-bottom: 1px solid #eee !important;
}
.border-bottom-last .border-bottom-notes:last-child,
.border-bottom-last .border-bottom-bills:last-child {
  border-bottom: 0px !important;
}
.bvs-list li,
.evaluations-list li,
.items-list li,
.notes-list li,
.products-list li {
  cursor: pointer;
}

.notes-list li {
  margin-bottom: 15px;
  background: #f8f8f8;
}

.bvs-list li:hover,
.evaluations-list li:hover,
.items-list li:hover,
.notes-list li:hover,
.products-list li:hover {
  background: var(--grey);
}

.item-list div,
.products-list div {
  margin-bottom: 10px;
}

.bvs-list div {
  margin-bottom: 0px;
}

.notes-list div:nth-child(n) {
  border-bottom: 1px solid var(--grey);
}

.notes-list div:last-child {
  border-bottom: none;
}

.bvs-list li,
.evaluations-list li,
.items-list li,
.products-list li {
  margin-bottom: 20px;
  background: var(--lightGrey);
}

#insertEmployees .btn-create-item,
#insertItem .btn-create-item,
#insertItem .btn-remove,
#insertProducts .btn-create-products,
#insertbv .btn-add-bv,
#offerCreate .btn-add-item,
#construction-diary .btn-add-item,
#offerCreate .btn-remove,
#specification .btn-create-item {
  margin-top: 0;
}

.btn-add-blue,
.btn-add-bv,
.btn-add-item,
.btn-create-item,
.btn-create-products,
.btn-remove,
.btn-remove-products,
.btn-print-diary {
  width: 61px;
  height: 61px;
  float: right;
  margin-top: 30px;
  color: var(--white);
  border: none;
  box-shadow: none;
  text-transform: uppercase;
  font-size: 1em;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  cursor: pointer;
}

.btn-add-blue {
  background: var(--blue);
}

.btn-add-blue:hover {
  background: var(--darkblue);
}
.btn-print-diary{
  background: var(--darkYellow);
}
.btn-print-diary:hover{
  background: var(--darkDarkYellow);
}
.deliveryNote {
  width: 100%;
}

#showEvaluations .btn-add-item,
#showHoursReports .btn-add-item,
.deliveryNote .btn-remove,
.hoursreporting .btn-remove,
.legalbreaks .btn-remove {
  margin-top: 0px;
}
.row.legalbreaks{
  justify-content: center;
}
.row.legalbreaks p.show{
  margin-top: 25px;
}
.legalbreak-main{
  margin-top: 150px;
}
#showHoursReports .btn-add-item {
  margin-top: 0;
}

.btn-add-bv,
.btn-create-item,
.btn-create-products,
.btn-remove-products {
  width: 100%;
}

.items-list .btn-create-item,
.products-list .btn-create-products {
  margin: 0;
}

.btn-add-bv,
.btn-add-item,
.btn-add-item,
.btn-create-item,
.btn-create-products {
  background: var(--green);
}

.btn-remove,
.btn-remove-products,
#sqlquerys .btn-create-item,
#workerQuerys .btn-create-item {
  background: var(--red);
}

.btn-remove-products:hover,
.btn-remove:hover,
#sqlquerys .btn-create-item:hover,
#workerQuerys .btn-create-item:hover {
  background: var(--darkRed);
}

.btn-add-item:hover,
.btn-create-item:hover,
.btn-create-products:hover {
  background: var(--darkGreen);
}

.btn-create-item:disabled,
.btn-remove-products:disabled,
.btn-save-directionWork:disabled,
.btn-save-product:disabled,
.calcDataEmployee .btn-creat-item:disabled,
.btn-print-diary:disabled {
  background: #e3e3e3 !important;
  cursor: no-drop !important;
}

.show .row p {
  margin-bottom: 5px;
}

.show0 span {
  display: block;
}

#spanSmall,
.show0 span,
.show span,
.showSpan span {
  display: inline;
  color: #717171;
  font-size: 0.7em;
}
.dif-span span{
  color: #212121;
  font-size: 1em;
}
.dif-span p{
  padding: 0 !important;
  font-weight: bold;
  position: relative !important;
}
.hidetext span {
  display: none;
}

.show0 span {
  display: block !important;
}

.fullwidth {
  margin-bottom: 0px;
}

.fullwidth .x-red {
  margin-top: 0;
}

.fullwidth p {
  margin-bottom: 0 !important;
  border-right: 2px solid #fff !important;
}
.bills .fullwidth p{
  border-right: 0 !important;
}
.modal-body .fullwidth p{
  border-right: 0px !important;
}
ul.notes-list .fullwidth p{
  border-right: none !important;
}
.fullwidth .row {
  margin-bottom: 0;
}

.show-bvs-btn,
.show-evaluations-btn,
.show-items-btn,
.show-products-btn {
  display: grid;
  margin-bottom: 40px;
  grid-gap: 1em;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 3;
  /* display: inline-grid; */
}

#showBillbooks .show-items-btn,
#showbvs .show-items-btn {
  display: flex;
  margin-bottom: 40px;
  margin-bottom: 40px;
  grid-gap: 0;
  grid-template-columns: 0;
  grid-template-rows: 0;
}
#showBillbooks{
  padding-bottom: 0;
}
.minus-margin{
  margin-top: -50px;
}
#showbvs .showSpan .span p {
  font-weight: bold;
}

.margin-zero {
  margin-top: 0;
}

.show-bvs-btn p,
.show-items-btn p,
.show-products-btn p {
  padding: 10px 0;
  text-align: right;
}

.show-bvs-btn input,
.show-evaluations-btn input,
.show-items-btn input,
.show-products-btn input,
.offer-show-items input,
.diary-show-items input {
  max-width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-left: 2px solid var(--greenSeyband);
}
.diary-show-items input{
  border-left: 2px solid var(--yellow);
}
.offer-show-items select{
  max-width: 100%;
  padding: 10px 5px;
  border: 1px solid #ddd;
}
.show-bvs-btn span,
.show-items-btn span,
.show-products-btn span {
  padding: 0 15px;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
}

.modal-open {
  overflow: hidden;
}

.modal {
  z-index: 1050;
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.modal.fade .modal-dialog {
  top: 50%;
  height: 95%;
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  -o-transition: -o-transform 0.3s ease-out;
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
}

.modal.in .modal-dialog {
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  position: relative;
  width: 80%;
  margin: 10px;
  margin: 0 auto;
}

.modal-content {
  position: relative;
  background-color: #fff;
}

.modal-backdrop {
  z-index: 1040;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  opacity: 0.5 !important;
}

.modal-backdrop.fade {
  opacity: 0;
  filter: alpha(opacity=0);
}

.modal-backdrop.in {
  opacity: 0.5;
  filter: alpha(opacity=50);
}

.modal-header {
  display: inline-block !important;
  width: 100%;
  padding: 28px 0;
  text-align: center;
  font-size: 1.8em;
}

.modal-header .close {
  position: absolute;
  top: 29px;
  right: 15px;
  border: none;
  background: none;
  cursor: pointer;
  transition: all .3s;
}

.modal-header .close:hover {
  color: var(--darkRed);
}

.modal-header span {
  display: block;
  margin-top: 20px;
  font-size: 0.7em;
}

.modal-title {
  display: inline-block;
  margin: 0;
  line-height: 1.42857143;
}

.modal-body {
  position: relative;
  padding: 15px;
}

.modal-body p {
  color: #7d7d7d;
  font-size: 0.7em;
}

.modal-footer {
  padding: 0px !important;
  border-top: none !important;
  text-align: right;
}

.modal-footer .btn + .btn {
  margin-bottom: 0;
  margin-left: 5px;
}

.modal-footer .btn-group .btn + .btn {
  margin-left: -1px;
}

.modal-footer .btn-block + .btn-block {
  margin-left: 0;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

.btn-blue,
.btn-darkred,
.btn-green,
.btn-red,
.x-red {
  width: 100%;
  padding: 10px 0;
  font-size: 1em;
  cursor: pointer;
}

.btn-darkred {
  color: var(--white);
  border: none;
  background-color: var(--redBtn);
}

.btn-red {
  color: var(--white);
  border: none;
  background-color: var(--red);
}

.x-red {
  margin-top: 20px;
  padding: 0;
  color: var(--red);
  border: none;
  background-color: var(--white);
  line-height: 2.5em;
}

.btn-green {
  color: var(--white);
  border: none;
  background-color: var(--green);
}

.btn-blue {
  color: var(--white);
  border: none;
  background-color: var(--blue);
}

.btn-red:hover {
  background: var(--darkRed);
}

.btn-green:hover {
  background: var(--darkGreen);
}

.btn-blue:hover {
  background: var(--darkblue);
}

.show {
  display: block !important;
}
#sqlquerys .show {
  width: 100%;
}
.hide {
  display: none;
}

.error {
  color: var(--darkRed);
}

.errorSearch,
.errorEmpty {
  text-align: center;
}

.errorInput,
.errorSearch,
.errorEmpty {
  display: block;
  margin: 0 auto;
  padding-top: 20px;
  color: var(--darkRed);
  font-weight: bold;
}
.SqlQuerys .errorEmpty,
.SqlQuerys .errorEmpty h5,
.WorkerQuerys .errorEmpty,
.WorkerQuerys .errorEmpty h5 {
  margin-right: 0;
  text-align: center;
  padding-top: 10px;
}
.errorEmpty {
  padding-top: 80px;
}

.btn-start,
.btn-start-blue,
.btn-start-red,
.btn-start-yellow,
.btn-refresh {
  display: block;
  width: 100%;
  padding: 35px 25px;
  color: var(--white);
  border: 5px solid #fff;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  font-size: 2em;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  /* Safari */
}
.btn-refresh{
  font-size: 1.2em;
  padding: 10px 15px;
  max-width: 350px;
  margin: auto auto;
  cursor: pointer;
}
.btn-start {
  background: var(--greenSeyband);
}

#insertHoursReporting .wrapper-btn-text a:hover,
#insertItem .wrapper-btn-text a:hover,
#insertProducts .wrapper-btn-text a:hover,
#insertbv .wrapper-btn-text a:hover,
.btn-start:hover {
  background: var(--green);
}

.btn-start-blue {
  background: var(--blue);
}

#insertCustomer .wrapper-btn-text a:hover,
#insertEmployees .wrapper-btn-text a:hover,
#insertEvaluation .wrapper-btn-text a:hover,
#insertSupplier .wrapper-btn-text a:hover,
.btn-start-blue:hover {
  background: var(--darkblue);
}

.btn-start-red {
  background: var(--redBtn);
}

#insertCalcHourlyWage .wrapper-btn-text a:hover,
.btn-start-red:hover {
  background: var(--darkRed);
}

.btn-start-yellow,
.btn-refresh {
  background: var(--yellow);
}

#baseData .wrapper-btn-text a:hover,
#directionWork .wrapper-btn-text a:hover,
#directionWorkMain .wrapper-btn-text a:hover,
#offerCreate .wrapper-btn-text a:hover,
#offerbook .wrapper-btn-text a:hover,
.btn-start-yellow:hover,
.btn-refresh:hover,
#construction-diary .wrapper-btn-text a:hover {
  background: var(--darkYellow);
}

.section-start {
  border-top: 5px solid var(--greenSeyband);
  background: var(--grey);
}

.section-start-login {
  height: 100vh;
  border-top: 5px solid var(--greenSeyband);
  background: var(--grey);
}
.section-start-login p{
  position: absolute;
  left: 10px;
  top: 5px;
}
.section-start-login .col-4.offset-4.center div{
  position: relative;
}
.section-start-login .col-4.offset-4.center div input{
  padding-bottom: 5px;
}
.section-start-login input {
  display: block;
  margin-bottom: 30px;
  font-size: 1.5em !important;
}

.section-start-login .col-4 {
  max-width: 400px;
  margin: 0 auto;
}

#login-button {
  width: 100%;
  margin-top: 25px;
  color: white;
  background: var(--greenSeyband);
}

#login-button:focus,
#login-button:hover {
  background: var(--green);
  cursor: pointer;
}

.section-start hr {
  margin: 75px 0;
}

input[type="checkbox"] {
  width: auto;
  margin-left: 15px;
  transform: scale(1.4);
}

.inline {
  display: flex;
}

hr {
  margin: 25px 0;
  border:.5px solid #eaeaea;
  width: 100%;
}

li {
  list-style-type: none;
}

.list-header {
  background: var(--darkgrey);
  font-weight: bold;
}
.list-header p {
  border-right: 2px solid #fff;
}
.calcDataEmployeeShow .list-header {
  margin: -5px -5px 15px -5px;
  padding-left: 10px;
}

.list-header span,
p span {
  padding-bottom: 5px;
  vertical-align: bottom;
  font-size: 0.7em;
}

.list-header input[type="checkbox"] {
  height: 20px;
  margin: 10px 15px auto !important;
}

.fullwidth {
  width: 100%;
}

.calcData input {
  width: 150px;
  margin-left: 20px;
}

.calcDataEmployeeShow span {
  display: inline-block;
  width: 250px;
  margin-left: 20px;
  padding: 0px 10px;
}

.calcDataEmployee input,
.calcDataEmployee select {
  width: 250px;
  margin-left: 20px;
  padding: 8px 10px;
}

.calcData:disabled input:hover,
.calcDataEmployee input:hover {
  cursor: no-drop;
}

.calcData input:disabled,
.calcDataEmployee input:disabled {
  background: #fff;
  cursor: no-drop;
}

input:required {
  box-shadow: none;
}

.calcDataEmployeeShow span {
  margin-left: 0;
  text-align: left !important;
  font-size: 1em;
}

.calcDataEmployeeShow hr {
  width: 100%;
  margin: 1px;
}

.calcDataEmployeeShow li,
.calcDataEmployeeShow li p {
  border: 0;
}

.calcDataEmployeeShow li:hover {
  font-weight: normal !important;
}

.calcDataEmployee label,
.modal-body .calcDataEmployee label {
  padding: 1px !important;
}

#showItems label,
#showProducts label {
  display: inline-block;
}

#smallerPaddingShow select {
  padding: 10px;
  background: #e3e3e3;
}

.calcDataEmployee span,
.modal-body .calcDataEmployee span {
  display: inline-block;
  min-width: 250px;
  text-align: right;
  font-size: 1em;
}

.calcDataEmployeeShow .col-6 {
  border: 10px solid white;
}

.calcData label:nth-child(odd) {
  text-align: right;
}

.calcData label:nth-child(even) {
  text-align: left;
}

.calcData span {
  display: inline-block;
  min-width: 225px;
  text-align: left;
  font-size: 1em;
}

.calcData .col {
  margin-top: 40px;
}

.calcDataEmployee hr {
  width: 75%;
  margin: 0 auto;
  border-top: 1px solid var(--darkgrey);
}

.modal-font-calcEmployee p {
  margin-bottom: 30px;
  color: var(--black);
  text-align: center;
  font-size: 1.3em;
}

.tab input {
  margin-right: -30px;
}

.hoursreporting input {
  position: relative;
  margin-right: 0;
}

.tab span {
  display: inline-block;
  color: lightgray;
  font-size: 1.3em;
}

#span-icon {
  display: inline-block !important;
  position: absolute;
  right: 0px;
  padding: 15px 20px 15px 0;
  color: lightgray;
  font-size: 1.3em;
}

.tab-select #span-icon {
  right: 30px;
}

.hoursreporting span,
.legalbreaks span {
  display: none;
  padding: 0px 0 5px 10px;
  color: var(--spangrey);
  text-align: left;
  font-size: 0.85em !important;
}

.span-row-0 span {
  display: block;
}

.hoursreporting label,
.legalbreaks label {
  padding: 0;
}

.hoursreporting input,
.hoursreporting select,
.legalbreaks input {
  height: 50px;
  padding: 10px;
}

.hoursreporting select {
  padding: 0 10px 0 10px;
}

.hoursreporting-errorSearch {
  display: inline;
  text-align: center;
}

.hoursreporting .btn-add-item,
.hoursreporting .btn-remove,
.legalbreaks .btn-add-item,
.legalbreaks .btn-remove {
  width: 50px;
  height: 50px;
}

.hide-span span {
  display: none;
}

#showHoursReports .btn-add-item {
  width: 61px;
  height: 61px;
}

#showHoursReports select {
  background: var(--grey);
}

#showHoursReports p span {
  color: var(--spangrey);
}

.hoursReporting-li .row {
  margin-bottom: 2px;
  cursor: pointer;
}

.hoursReporting-li p {
  background: var(--grey);
}

#upload {
  padding: 0;
  color: black;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  height: 60px;
  cursor: pointer;
  margin-top: 0;
}

.csv-input input {
  opacity: 0;
  cursor: pointer;
}

.csv-input {
  height: 61px;
  margin-top: 30px;
  padding: 0px;
  background: var(--blue);
  cursor: pointer;
}

.csv-input:hover {
  background: var(--darkblue);
}

.offerCreate .csv-input,
#specification .csv-input {
  background: var(--yellow);
}

.offerCreate .csv-input:hover,
#specification .csv-input:hover {
  background: var(--darkYellow);
}

#upload-icon {
  position: absolute;
  top: 65%;
  left: 50%;
  font-size: 1.4em;
  transform: translate(-50%, -50%);
}

.offerCreate #upload-icon,
#specification #upload-icon {
  top: 50%;
}

.directionWorkListInput button:disabled,
.hoursreporting button:disabled {
  background: #e3e3e3 !important;
}
 

.hoursComplete {
  display: inline-block;
  margin-top: 20px;
}

.hoursComplete span {
  color: black !important;
  font-size: 1em;
}

.hoursComplete hr {
  margin: 6px 0;
  border-color: black;
}

.print {
  display: none;
}

.one-page:last-child {
  page-break-after: auto;
}

.wage-accounting hr {
  margin: 0;
  border: 0;
}

.wage-accounting {
  margin-top: 45px;
}

.wage-accounting p {
  text-align: center;
}

.directionWorkHoursList .row,
.wage-accounting .row {
  margin-bottom: 2px;
  border-bottom: 1px solid var(--darkgrey);
}

.wage-accounting p span {
  font-size: 1em;
}

.directionWorkHoursList h4 {
  margin-bottom: 0;
  padding-bottom: 0;
  text-align: left;
  font-size: 1.1em;
}
 

@media print {
   @page {
    size:  210mm 297mm ; 
    }
  section {
    padding: 40px;
    padding-bottom: 0px;
  }

  .hoursReporting-li p {
    line-height: 1em;
  }

  .no-print {
    display: none;
  }

  .print {
    display: flex;
  }
  #table-printWiderruf .print {
    display: block;
  }
  .padding-bottom-1cm {
    padding-bottom: 2cm;
  }
  .print h4 {
    text-align: left;
  }

  .print img {
    display: flex;
    max-width: 150px;
  }
  .underline img{
    max-width: 250px;
  }
  .print-font p {
    font-size: 11px;
  }

  .hoursReporting-li p {
    background: none;
  }

  .wage-accounting {
    /* height: 20px; */
    margin-top: 10px;
  }

  .wage-accounting hr {
    display: flex;
    width: 100%;
    border-top: 1px solid black;
  }

  .wage-accounting .row {
    margin-bottom: 0;
    border-bottom: 1px solid black;
  }

  .wage-accounting .offset-3 {
    margin-left: 0;
  }

  .wage-accounting p {
    text-align: left;
    font-size: 12px;
  }

  .billbooks-list p,
  .billbooks-list span {
    font-size: 0.7em;
    font-weight: lighter;
  }

  .billbooks-list .list-header span {
    font-size: 1em;
    font-weight: bold;
  }

  .billbooks-list .list-header {
    border-bottom: 1px solid black;
  }

  .billsbook .showSpan {
    display: none;
  }

  .billsbook .showSpan:first-child {
    display: block;
  }

  .billsbook #font-normal {
    padding: 10px 0;
  }
  .construction-diary{
    background: none;
  }
 
  #table-offer thead{
       display: table-header-group;
    width: 100%;
    height: 90px; /* Adjust the height as needed */
  }
  #table-offer tfoot{
    width: 100%;
    height: 75px;
           display: fixed;
           left: 0;
           right: 0;
           

   }
   .footer.print,
   #table-offer tfoot,
   #table-offer tfoot tr,
   #table-offer tfoot td{
    width: 100%;
   }
 }
 
.unterschrift {
  display: block;
  position: relative;
  margin-top: 45px;
}

.unterschrift p {
  text-align: right;
  font-size: 10px;
}

.unterschrift hr {
  margin: 3px 0;
  border-top: 1px solid black;
}

.content {
  display: block;
  width: 100%;
}

.druck-p {
  color: var(--greenSeyband);
  text-align: center;
  font-size: 1.3em;
  font-weight: bold;
}

.billsbook input,
.billsbook select {
  height: 61px;
}

.billbooks-list .list-header span {
  font-size: 1em;
}

.baseData p,
.bills p,
.billsbook p,
.buildingProjects p,
.customer p,
.deliveryNote p,
.directionWorkListInput .input p,
.directionWorkListInput p,
.directionWorkReportInput .input p,
.directionWorkReportInput p,
.employees p,
.evaluation p,
.items p,
.modal-body p,
.offerCreate p,
.products p,
.section-start-login,
.supplier p,
.SqlQuerys p,
.workerQuerys p,
.filter p {
  position: absolute;
  height: auto;
  margin: 0;
  padding: 5px 10px;
  color: #767676;
  font-size: 0.7em;
}
.filter input,
.filter select{
  border: 1px solid #ddd;
  border-left: 2px solid var(--yellow)
}
.width-checkbox {
  display: inline-block;
}
.div-checkbox {
  padding: 0px 0 20px 15px;
}

.div-checkbox h2 {
  position: relative;
  padding: 0;
  color: black;
  font-weight: bold;
  display: block;
  padding: 5px 0;
  margin-top: 0;
}
.div-checkbox label {
  display: inline-block;
  padding: 0;
  text-align: left;
}
.div-checkbox input {
  height: auto;
  margin-right: 40px;
}

.section-start-login {
  position: relative;
  height: 100vh;
}

#directionWorkList h2,
#directionWorkReport h2 {
  margin-top: 90px;
  text-align: center;
}

#directionWorkList h2 span,
#directionWorkReport h2 span {
  font-size: 0.8em;
}

#directionWorkList .offset-1,
#directionWorkReport .offset-1 {
  margin-left: 8.333333% !important;
}

.workHours-list-checkbox input {
  height: 20px;
}

.copy-field span {
  position: absolute;
  padding: 5px;
  color: #767676;
  border-right: none;
  font-size: 0.7em;
}

.center {
  text-align: center;
}

.copy-field {
  padding: 0;
}

.show0 .copy-field input {
  height: auto;
  padding: 15px 0 5px 0px;
  background: var(--lightgray);
  cursor: copy;
}

.copy-field input {
  height: auto;
  padding: 0px;
  background: var(--lightgray);
  cursor: copy;
}

.copy-field input:active,
.copy-field input:focus,
.copy-field input::selection {
  color: inherit;
  background: none;
}

#copied-fa {
  display: none;
}

.show0 .fa-hide,
.show0 .fa-show {
  position: absolute;
  top: 20px;
  right: 15px;
  transition: all 0.5s;
}

.fa-hide,
.fa-show {
  position: absolute;
  top: 5px;
  right: 15px;
  transition: all 0.5s;
}

.fa-hide {
  opacity: 0;
}

.fa-show {
  opacity: 1;
}

#directionWorkList input,
#directionWorkList select,
#directionWorkReport input,
#directionWorkReport select,
#showEvaluations input,
#showEvaluations select {
  background: var(--lightGrey);
}

.DeliveryNotesListReport input,
.DeliveryNotesListReport select {
  height: 45px;
}

.DeliveryNotesListReport .btn-add-item,
.DeliveryNotesListReport .btn-remove {
  width: 45px;
  height: 45px;
}

.DeliveryNotesListReport #span-icon {
  padding: 9px 20px 15px 0;
}

table {
  width: 100%;
  max-width: 600px;
  margin: 75px auto 0;
  margin-top: 75px;
}

.page-break table {
  max-width: 100%;
  margin: 0;
  margin-top: 0;
}

table tr {
  display: block;
  padding: 15px 0;
  font-size: 1.3em;
}

table td:first-child {
  min-width: 300px;
  font-weight: bold;
}

table tr td:nth-child(2) {
  width: 100%;
  text-align: right;
}

 
#table-printConfirmation table {
  z-index: 0;
  display: block;
  position: absolute;
  top: 0;
   width: 22.5cm;
  max-width: 22.5cm;
  margin: 2cm 0;
} 
#table-printWiderruf {
  z-index: 0;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 22.5cm;
  max-width: 22.5cm;
  margin: 1cm 0;
  }
  #table-printWiderruf .page-break-before{
    padding-top: 2cm;
    display: block;
  }
  .page-break-before{
    page-break-before: always;
  }
.white-space {
  white-space: pre-line;
}

 

#table-offer table tr {
   padding: 0;
  font-size: 9.5pt;
  line-height: 13pt;
}
#table-offer{
  height: 100%;
  background: green;
  width: 100%;
  position: relative;
}
#table-offer table td:first-child {
  min-width: auto;
  font-size: 9.5pt;
  font-weight: lighter;
  vertical-align: top;
}

#table-offer table tr td:nth-child(2) {
  width: 100%;
  text-align: left;
}

.green {
  color: var(--greenSeyband);
}

.red {
  color: var(--darkRed);
}

#table-border {
  border-bottom: 1px solid gray;
}

#font-normal,
#font-normal mark,
#font-normal span {
  display: inline !important;
  padding-right: 0;
  padding-bottom: 0;
  color: var(--black);
  font-size: 1em;
}

.font-small {
  color: #717171;
  font-size: 0.7em;
}

.copy-field #font-normal {
  width: 100%;
  position: relative;
  min-height: 15px;
  display: inline-block !important;
}

.copy-field input {
  z-index: 1;
  position: absolute;
  top: 0;
  padding: 5px 0 5px;
  opacity: 0;
}

#overlay-copy-field {
  z-index: 0;
  position: absolute;
  top: 0;
  width: 100%;
  padding: 5px 0 5px 0;
  font-size: 0.9em;
}

.text-right #font-normal,
.text-right #font-normal mark,
.text-right #font-normal span {
  display: inline !important;
  text-align: right;
}

mark {
  background: #ccff90;
}

.calcEmployee #font-normal,
.calcEmployee #font-normal mark,
.calcEmployee #font-normal span,
.employee #font-normal,
.employee #font-normal mark,
.employee #font-normal span {
  display: inline-block !important;
  width: auto;
  padding: 0;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.cursor {
  cursor: default !important;
}

.billbooks-list h5 {
  text-align: right;
  font-size: 1.3em;
}

.billbooks-list .list-header p {
  padding: 10px 5px;
}

.billsbook-list .list-header {
  margin-bottom: 20px;
}

.print-button {
  width: 60px;
  height: 60px;
  margin-bottom: 35px;
  text-align: center;
}

.inline span {
  display: inline !important;
}

.scrollable-div {
  display: block;
  width: 90%;
  height: 500px;
  margin: 0 auto;
  padding: 15px;
  overflow-y: auto;
  border: 1px solid #ddd;
}

.row-width-90 {
  display: flex;
  width: 90%;
  margin: 0 auto;
  margin-top: 30px;
}

.row-width-90 p {
  font-size: 1.1em;
}

#text-left {
  text-align: left;
}

#text-center {
  text-align: center;
}

#text-right {
  text-align: right;
}

.border-bottom {
  width: 350px;
  margin-bottom: 10px;
  border-bottom: 1px solid #000;
}

.border-bottom-fullwidth {
  border-bottom: 1px solid #000;
  background: #eee !important;
}

#padding-5 {
  padding-top: 5px;
}

.border-bottom-fullwidth .col,
.border-bottom-fullwidth .col-8 {
  margin-bottom: -5px;
  font-weight: bold;
}

.border-bottom-list {
  border-bottom: 2px solid #ddd;
}
ul li.border-bottom-list:last-child,
.print div:last-child .border-bottom-list{
  border-bottom: 0px;
}
.hr-header {
  margin-top: -20px;
}

.hr-header .col-md-12:last-of-type .border-bottom {
  margin-bottom: 20px;
}

.hr-header h1 {
  margin-top: 0;
}

.row-zero .row {
  margin-top: -10px !important;
}

.row-zero span {
  font-size: 1em;
}

.border-top {
  margin-top: -2px;
  border-top: 2px solid #000;
}

.border-bottom-header {
  border-top: 2px solid #000;
}

.margin-top {
  margin-top: 25px;
}

.bigger-font h3 {
  text-transform: uppercase;
  font-size: 1.1em;
  font-weight: bold;
}

.bigger-font {
  margin-bottom: 15px;
}

textarea {
  display: block;
  box-sizing: border-box;
  width: 90%;
  height: auto;
  margin: 0 auto;
  margin: 0 auto;
  padding: 20px 10px 10px 10px;
  overflow: auto;
  border: 0px solid #ddd;
  font-family: inherit;
  line-height: 1.7em;
  min-height: 60px;
}

.modal-body textarea {
  width: 100%;
  border: 1px solid #ddd;
}

.baseData textarea,
.offerCreate textarea,
.products textarea {
  width: 100% !important;
}

.HoursListReport #span-icon {
  padding: 13px 38px 15px 0;
}

.page-break-hoursreporting,
.page-break-offerPrint {
  page-break-after: always;
}
.page-break-before {
  page-break-before: always;
}
.page-break-before-auto {
  page-break-before: auto;
}
.page-break-inside-always {
  page-break-inside: auto;
}

@media print {
 
  .border-bottom-list {
    margin-bottom: 0px !important;
    border-bottom: 2px solid #ddd;
  }

  #hr-bold {
    margin: 0px 0;
    border-top: 2px solid #000;
  }
 
  .page-break-inside {
 
    page-break-inside: avoid;
  }
  #offerProducts{
    display: block;
    position: relative;
  }
 .td-with-100{
  padding: 3px 0;
 }
 .flex-end{
  justify-content: end;
  align-content: end;
  width: 100%;
 }
   #table-offer .margin-top-150{
    margin-top: 150px;
  }
  #table-offer table{
    page-break-before: always;
  }
 
 .td-width-100{
  width: 100%;
  display: inline-block;
 }
  .scrollable-div {
    width: 100%;
    height: auto;
    padding: 0;
    overflow-y: auto;
    border: none;
    display: block;
  }

  #directionWork {
    padding: 0 !important;
  
  }
  #directionWorkReport,
  #directionWork{
    margin-top: 1cm;
     width: 22.5cm;
     max-width: 22.5cm;
   }
   #directionWorkReport .row.border-bottom-list,
   #directionWorkReport .row.margin-top {
     width: 22.5cm;
     max-width: 22.5cm;
   }
 
  #directionWork h1 {
    margin: 40px auto 0px;
    padding: 0;
    padding-bottom: 30px;
    text-align: center;
    font-size: 1.5em;
  }

  #directionWork img {
    margin-top: 30px;
  }

  #directionWorkReport h4 {
    margin: 20px 0 4px 0;
    font-size: 1em;
  }

  #directionWorkReport h2 {
    margin: 0 0 10px 0;
    margin-top: 0 !important;
    font-size: 1.3em;
  }

  #directionWorkReport .print {
    display: block;
  }

  #directionWorkReport .row {
    margin-bottom: 0;
  }

  #directionWorkReport .col {
    padding-bottom: 10px;
  }

  .page-break {
    page-break-after: always;
  }

  .page-break .row {
    width: 100%;
  }

  .block {
    display: block;
  }

  #directionWorkReport p {
    font-size: 0.8em;
  }

  #directionWorkReport .list-header {
    width: 100%;
    margin-top: 25px;
  }

  #directionWorkReport hr {
    margin: 10px 0;
    border-top: 1px solid black;
  }
#directionWorkReport .margin-top{
  display: inline-block;
}
  #directionWork {
    border-top: 0;
  }

  section {
    padding: 0 !important;
  }

  .hr-header {
    margin-top: -50px !important;
  }

  .hr-header h1 {
    margin-bottom: 20px;
    text-align: left !important;
    font-size: 2.5em !important;
  }

  textarea {
    box-sizing: border-box;
    width: 100%;
     padding: 0 5px;
     border: none;
    font-family: inherit;
    font-size: 0.9em;
    line-height: 1.75em;
     page-break-inside: auto;
  }
  .col {
    padding: 5px !important;
  }

  tfoot.report-footer {
    display: table-footer-group;
  }

  #table-offer tfoot {
    page-break-after: avoid;
    width: 100%
  }

  table {
    page-break-after: auto;
  }

  thead .row {
    margin-bottom: 0 !important;
    border-bottom: none !important;
  }

  thead .row p {
    font-weight: bold;
  }

  #offer-table tfoot p {
    margin: 0;
    text-align: left !important;
    font-size: 7.5pt;
  }
 
}
 .justify-content{
  display: flex;
  align-items: center;
 }
 .justify-content input[type="checkbox"]{
  margin-left: 0;
  margin-right: 15px;
 }
.margin-input-fields input {
  margin-bottom: 20px;
}

.logo {
  display: block;
  width: 100%;
  float: right;
  margin-top: -0.6cm;
  text-align: right;
}

.logo img {
  float: right;
  margin: 0;
}

.anschrift span {
  display: flex;
  margin-bottom: 7pt;
  font-size: 7pt;
}

.anschrift p {
  font-size: 10pt;
  font-weight: bold;
  line-height: 16pt;
}

.kontakt p {
  font-size: 8pt;
  line-height: 11pt;
}

.kontakt p,
.zeichen p {
  font-size: 8pt;
}

.zeichen {
  margin-top: 1.7cm;
}
#table-printConfirmation .zeichen {
  margin-top: 2.5cm;
}
#table-printConfirmation h2 {
  font-weight: bold;
  margin-top: 1cm;
  margin-bottom: 2.5cm;
  text-align: center;
}
.angebot-daten {
  margin-top: 1.25cm;
}

.angebot-daten h3,
.angebot-daten h4 {
  margin: 0;
  text-align: left;
  text-transform: none;
  font-weight: bold;
  line-height: 23pt;
}

.angebot-daten h3 {
  font-size: 14pt;
}

.angebot-daten h4 {
  font-size: 12pt;
}

.anschreiben div {
  width: 100%;
  padding: 0;
  font-size: 10pt;
  line-height: 14pt;
  margin-bottom: 10px;
}
.confirmationText div,
.signingField,
.widerrufText p {
  font-size: 10pt;
  line-height: 14pt;
  min-width: 22.5cm;
  font-weight: normal;
}
small {
  font-size: 0.8em;
}
.widerrufText span {
  font-size: 10pt;
  line-height: 14pt;
  font-weight: normal;
  display: inline-block;
  min-width: 3cm;
  padding: 0;
}
.widerrufformularText .hr-margin {
  margin: 40px 0 40px 0;
}
.unterschriftAg .hr-margin {
  margin-top: 20px;
}
.widerrufformularText .padding-bottom-1cm {
  padding-bottom: 0.5cm;
}
.signingField {
  margin-top: 2cm;
}
.signingField hr {
  width: 6cm;
  border-top: 1.5px solid #000;
}
.hr-margin {
  margin: 70px 0 40px 0;
  border-top: 1.5px solid #000;
}
.signingField .text-right hr,
.signingFieldWiderruf .col-6 hr {
  text-align: right;
  display: inline-block;
  margin-right: 0;
}
.signingFieldWiderruf .col-6 {
  padding-left: 0;
}
.signingFieldWiderruf p {
  width: auto;
  min-width: auto;
}
.signingFieldWiderruf .col-6 hr {
  width: 6cm;
  border-top: 1.5px solid #000;
}
small {
  font-size: 9px;
  margin-bottom: 1.5cm;
  display: block;
}
.widerrufformularText small {
  margin-bottom: 0;
}
.anschreiben {
  margin-top: 0.8cm;
  font-size: 10pt;
}

.anschreiben .unterschrift {
  display: flex;
  margin: 0;
}

.anschreiben .unterschrift img {
  display: block;
  width: 160px;
  text-align: left;
  margin: 0;
}

.footer {
  margin-bottom: -0cm;
}

tfoot .col-4 {
  max-width: 6cm;
}

tfoot .col-2 {
  max-width: 4cm;
}

.smaller-col-3 {
  max-width: 4.5cm;
}

.smaller-col-3 p:nth-child(5) {
  margin-top: 5px;
}

tfoot .footer textarea {
  width: 100%;
  padding: 0;
  font-size: 7pt;
  line-height: 9pt;
}

tfoot .footer p {
  margin: 0;
  padding: 0;
  text-align: left !important;
  font-size: 7pt;
  line-height: 9pt !important;
}

tfoot .footer table,
tfoot .footer table tr {
  width: auto;
  max-width: auto;
  margin: 0 !important;
  padding: 0 !important;
  font-size: 7pt !important;
}

tfoot .footer table td {
  min-width: auto;
  line-height: 6pt !important;
}

tfoot .footer table tr {
  margin-left: -5px !important;
}

tfoot .footer table td:first-child {
  min-width: 80px !important;
  font-size: 7pt !important;
}

tfoot .footer table tr td:nth-child(2) {
  text-align: left;
}

tfoot .footer b {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.footer,
.footer-space  {
  height: 90px;
}
 
.header {
  position: fixed;
  top: 0;
  left: 0;
}

.footer {
  position: fixed;
  bottom: 0cm;
  left: 0cm;
}

 

.border-bottom-price p {
  display: block;
  padding: 0px 0;
  font-size: 9.5pt;
  font-weight: normal;
}

.text-after-products div {
  font-size: 10pt;
  line-height: 14pt;
  page-break-inside: avoid;
}

.header-liste {
  width: 22.5cm;
  border-bottom: 2px solid #000;
  font-size: 11.5px;
  font-weight: bold;
}
 
.width-price-complete {
  min-width: 2.7cm;
  max-width: 2.7cm;
}

.width-price-piece {
  min-width: 2.3cm;
  max-width: 2.3cm;
}

.width-unit {
  min-width: 2cm;
  max-width: 2cm;
}

.width-amnt {
  min-width: 1.3cm;
  max-width: 1.3cm;
}

.width-dsc-big {
  min-width: 12.2cm;
  max-width: 12.4cm;
}
.width-dsc-big p {
  white-space: pre-wrap;
}
.width-dsc-small {
  min-width: 12.3cm;
  max-width: 12.3cm;
}

.width-pos-big {
  max-width: 1.7cm;
  min-width: 1.7cm !important;
  margin-bottom: 0px;
}

.width-pos-small {
  max-width: 1.7cm;
  margin-bottom: 0px;
}

#offerProducts .row {
  margin-bottom: 0px;
}

.width-price {
  width: 3cm;
  max-width: 3cm;
}

.complete-price-between {
  margin: 10px 0;
}

.complete-price-between tr td:nth-child(2) {
  padding-right: 5px;
}

.border-bottom-price {
  border-bottom: 2px solid #000;
}

.complete-price-brutto,
.complete-price-netto {
  border-top: 2px solid #000;
}

.complete-price-brutto tr,
.complete-price-netto tr {
  padding: 10px 0 !important;
}

.complete-price-brutto td,
.complete-price-netto td {
  display: block;
}

#offerProducts .border-bottom-price,
.complete-price-between table,
.complete-price-brutto table,
.complete-price-netto table {
  width: 22.5cm;
  max-width: 100%;
  margin: 0;
}

.complete-price-between table tr {
  padding: 5px;
  font-size: 9.5pt;
  font-weight: lighter !important;
}

#offerProducts table thead td {
  font-weight: bold !important;
}

#offerProducts table tfoot {
  position: relative;
}

.complete-price-between table td,
.complete-price-brutto table td,
.complete-price-netto table td {
  display: inline !important;
}

.complete-price-between table td:last-of-type,
.complete-price-brutto table td:last-of-type,
.complete-price-netto table td:last-of-type {
  width: 1.5cm;
  padding-left: 20px !important;
}

.text-after-products {
  padding-top: 100px !important;
  page-break-after: avoid;
  width: 100%;
  display: block;
}

.text-after-products-negativ-margin {
  margin-bottom: -70px;
}

.complete-price-netto .hide {
  display: none !important;
}
.complete-price-netto {
  page-break-before: avoid;
}

#table-offer .hide {
  display: none !important;
}
#showOpenDeliveryNotes li .row{
  margin-bottom: 0;
}
#showOpenDeliveryNotes li,
#showOpenDeliveryNotes li p{
  transition: all .3s;
 }
 
 #showOpenDeliveryNotes li:hover p:not(span){
  font-weight: bold;
  cursor: pointer;
}
#showOpenDeliveryNotes li span{
  font-weight: normal !important;
 }
 #showOpenDeliveryNotes input{
  height: auto;
 }
 #showOpenDeliveryNotes .fullwidth .col-11 p:last-of-type{
  border-right: none !important;
 }
 #showOpenDeliveryNotes .align-center{
  align-items: center;
 }
@media print {
    html, body {
    width: 210mm;
    height: 297mm;
   }
 
  table  {
    position: relative !important;
  }
 
  .width-dsc-big .row {
    display: contents;
  }
  .logo {
    position: fixed;
    top: 0cm;
    right: 0cm;
    height: 3cm;
  }

  #offerCreate {
    border-top: none;
  }

 

  #offerPrint .print {
    display: block;
  }

  #directionWorkReport textarea {
    border: 0px solid black;
  }
  html,
  body {
    float: none;
    position: relative;
    height: initial !important;
    overflow: initial !important;
  }
}

.visible {
  visibility: visible;
  height: 45px;
  margin-bottom: -30px;
}

.invisible {
  visibility: hidden;
  height: 45px;
  margin-bottom: -30px;
}

.offerCreate .visible {
  margin-bottom: 0;
}

.saved p {
  position: relative;
  width: 100%;
  color: #000;
  text-align: right;
  font-size: 16px;
}

.complete-price-brutto tr td:nth-child(2),
.complete-price-netto tr td:nth-child(2) {
  display: inline-block !important;
  width: 3.5cm !important;
  text-align: right !important;
}

.complete-price-brutto tr:last-of-type td,
.complete-price-netto tr:last-of-type td {
  font-weight: bold !important;
}

.padding-zero {
  padding: 0;
}

.margin-bottom-input {
  margin-bottom: 10px;
}

#td-list {
  display: block;
  width: 100%;
  padding: 0;
  line-height: 20px;
}
.fa-3x {
  font-size: 2em;
  text-align: center;
  padding-top: 13px;
}
/* Tooltip container */
.tooltip {
  position: relative;
  display: block;
  cursor: pointer;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 300px;
  background-color: #eee;
  color: #000;
  padding: 15px;
  border-radius: 6px;
  font-size: 0.9em;
  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 200%;
  left: 50%;
  margin-left: -150px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #eee transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.group-sql {
  display: flex;
  padding: 0;
}
.anlagen {
  position: relative;
  display: block;
  max-width: 150px;
}
.anlagen p:first-child {
  font-weight: bold;
  border-top: 1px solid #000;
  padding-top: 10px;
  margin-top: 20px;
}
.anlagen p {
  display: block;
  margin-top: 5px;
}
.print-buttons .btn-save-product {
  height: 80px;
}
.btn-save-offer .btn-save-product {
  background: var(--green);
}
.btn-save-offer .btn-save-product:hover {
  background: var(--darkGreen);
}

.dsc-search {
  position: absolute;
  right: 10px;
  top: 50%;
  background: none;
  border: none;
  padding: 4px;
  color: var(--yellow);
  font-size: 1.4em;
  transition: 0.5s all;
  cursor: pointer;
  transform: translate(0%, -50%);
  opacity: 0;
  transition: 0.3s all;
  z-index: 0;
}
.dsc-search:hover {
  color: var(--darkYellow);
}
.col-5:hover .dsc-search {
  display: block;
  opacity: 1;
  z-index: 2;
}
.offerCreate p {
  z-index: 2;
}
.offerCreate textarea {
  padding-right: 40px;
  position: relative;
  z-index: 1;
}
.search-text p {
  padding-left: 0;
  font-weight: bold;
  font-size: 1em;
  color: black;
}
.product-search li {
  padding: 5px;
  transition: 0.5s all;
}
.product-search li:hover {
  background: var(--lightGrey);
  cursor: pointer;
}
.border-top-workerQuerys {
  border-top: 2px solid #444;
  font-weight: bold;
}
.workerQuerys .border-bottom-workerQuerys button {
  background: transparent;
  width: 100%;
  height: 100%;
  border: none !important;
  display: block;
  padding: 15px 0;
  font-size: 1.1em;
  text-align: left;
  cursor: pointer;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  font-weight: bold;
}
.workerQuerys .border-bottom-workerQuerys button svg {
  opacity: 0;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
}
.workerQuerys .border-bottom-workerQuerys button:hover svg {
  opacity: 1;
}
.workerQuerys .border-bottom-workerQuerys div:last-child button {
  text-align: right;
}
.wait {
  width: 100%;
  text-align: center;
}
#smaller-h3{
  font-weight: normal;
  text-align: center;
  padding: 20px 0;
}
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0 auto;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #444;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.m-b-50{
  margin-bottom: 50px;
}
.scroll-to-top{
  position: fixed;
  right: 15px;
  bottom: 15px;
  cursor: pointer;
  transition: all .3s;
  font-size: 2em;
  color: #ddd;
}
.scroll-to-top:hover{
  color: #939393;
}
.selectItem button{
  background: var(--green);
 border: none;
padding: 10px;
color: #fff;
cursor: pointer;
transition: all .3s;
-webkit-transition: all .3s;
text-transform: uppercase;
font-size: 1em;
}
.selectItem button:hover{
  background: var(--darkGreen)
}
.selectItem .title span{
  color: #767676;
font-size: 0.7em;
}
.align-flex-center{
  display: flex;
align-items: center;
border-bottom: 1px solid #e3e3e3;
}
.selectItem .align-flex-center:last-child{
  border-bottom: 0px;
}
.center-modal{
  position: absolute !important;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%,-50%) !important;
  -webkit-transform: translate(-50%,-50%) !important;
  height: auto !important;
}
.select-offer{
  z-index: 999;
}
.select-offer select{
  height: 30px;
  padding: 5px;
  width: auto;
}
 
.offer-date-width{
  max-width: 90px;
}
.offer-version-width{
  max-width: 60px;
}
.offer-nr-width,
.offer-customerId-width{
  max-width: 90px;
}
.offers-list .col-11{
 
    -ms-flex: 0 0 90%;
    flex: 0 0 90%;
    max-width: 90%;
 
}
.col.select-offer{
   
  -ms-flex: 0 0 10%;
  flex: 0 0 10%;
  max-width: 10%;
}
.offer-opacity-1,
.offer-opacity-0{
  transition: all .3s;
  -webkit-transition: all .3s;
  margin-left: 10px;
}
.offer-opacity-0{
  opacity: 0;
}
.offer-opacity-1{
  opacity: 1;
}
.showStatusList{
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0;
  right: 0;
  background: transparent;
  transition: all .3s;
  -webkit-transition: all .3s;
}
.showStatusList.green{
  background: var(--green);
}
.showStatusList.red{
  background: var(--red);
}
@media(max-width: 1200px){
  .offers-list .col-11,
  .col.select-offer{
 
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
 
}
 
}
.absolute-span span{
  position: absolute;
  left: 10px;
  top: 8px;
  color: #717171;
}
.redBorder input{
  border: 1px solid red;
}
.showBanner{
  position: fixed;
  right: 30px;
  bottom: 30px;
  background: #fff;
  box-shadow: 0 0 10px #e3e3e3;
  opacity: 1;
  transition: all .3s;
  z-index: 3;
  padding: 15px;
  display: block;
}
.hideBanner{
  position: fixed;
  transition: all .3s;
   opacity: 0;
   right: 30px;
   bottom: 30px;
 }
 .info-offer{
   display: block;
   position: relative;
   margin-top: 100px;
 }
 .info-offer p{
   position: relative;
   padding: 0;
 }
 .info-offer hr{
   margin: 5px 0 10px 0;
    width: 100px;
    border-bottom: .5px solid #bdbdbd;
 }
 .smaller-input button{
   background: none;
   background-color: transparent;
   border: none;
   height: 55px;
   font-size: 1.5em;
   cursor: pointer;
   padding-right: 15px;
 }
 .border-print-cd,
 .border-hours-print-cd{
   border: 1px solid black;
   min-height: 2cm;
 }
 table.border-print-cd,
 table.border-hours-print-cd{
   width: 100%;
   max-width: 100%;
   margin: 0;
   font-size: 1em;
 }
   table.border-print-cd td:first-child,
   table.border-print-cd td:nth-child(2),
   table.border-print-cd th:first-child,
   table.border-print-cd th:nth-child(2){
    max-width: auto;
    min-width: 200px;
    width: auto;
    text-align: left;
    font-weight: normal;
 }
 table.border-print-cd th{
   text-decoration: underline;
   font-weight: normal;
 }
 table.border-hours-print-cd tr th:first-child,
 table.border-hours-print-cd tr td:first-child{
   min-width: 150px;
   text-align: left;
 }
 table.border-hours-print-cd tr th{
   font-weight: normal;
  font-style: italic;
}
table.border-hours-print-cd tr th,
table.border-hours-print-cd tr td{
  text-align: right;
  padding: 2px 0;
 }
table.border-print-cd tr,
table.border-hours-print-cd tr{
  font-size: 1em;
  padding: 0;
}
table.border-hours-print-cd tr.row{
  margin-bottom: 5px;
}
.diaryPrint h1{
  text-align: center;
  font-size: 16pt;
  margin-top: 0;
  margin-bottom: 5px;
}
.diaryPrint h2{
  margin-bottom: 1px;
  font-size: 13pt;
  margin-top: 5px;
}
.diaryPrint p{
  font-size: 11pt;
}
.underline{
  border-bottom: 1px solid black;
}
.align-center{
  display: flex;
  align-items: end;
}
.underline img{
  margin-right: 50px;
   height: auto;
}

button.diary-remove-button svg,
button.diary-add-button svg{
  transition: all .3s;
}
button.diary-remove-button:hover svg{
  color: var(--darkRed);
}
button.diary-add-button:hover svg{
  color: var(--darkGreen);
}
.popupsaved{
  position: fixed;
  top: -75px;
  transition: all.3s;
  background: #fff;
  padding: 10px;
  left: 50%;
  transition: top 1s;
  transform: translate(-50%,0);
  box-shadow: 0 0 10px #c8c8c8;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  z-index: 9;
}
.show.popupsaved{
  top: 0px;
}
.offerCreate textarea{
  min-height: 150px;;
}
 @media print{
 
   .textarea p{
    overflow: visible;
    height: auto;
    white-space: pre-wrap;
    line-height: 1.5em;
   }
 }
 .padding-left-10{
  padding-left: 10px;
 }
 .padding-left-10 svg{
  margin-left: 5px;
  margin-top: 5px;
 }
 .padding-left-10 span{
  cursor: pointer;
  font-size: 1em;
  transition: all .3s;
 }
 .padding-left-10 span:hover{
  color: #15141A;
 }
 .qr-code-row{
  max-width: 950px;
  margin: 0 auto ;
   padding: 15px 0;
 }
 .qr-code-row .row{
  border-bottom: .5px solid #eaeaea;
  max-width: 400px;
  margin-top: 10px;
  padding-bottom: 10px;
 }
 .qrCode .qr-code-row:last-child{
  border-bottom: none;
 }
 .qr-code-row .col-md-9,
  .qr-code-row .col-md-3{
    display: flex;
    align-items: center;
 }
 .qr-code-row button{
  background: var(--redBtn);
  color: #fff;
  padding: 15px;
  border: none;
  cursor: pointer;
  margin: 0 0 0 auto;
 }
 .modal-qr-code{
  max-width: 600px;
  text-align: center;
 }
  .modal-qr-code h4{
   margin: -35px auto 0;
   text-transform: none;
   font-size: 1.2em;
 
 }
 .geo-modal img{
   display: inline !important;
 }
 .leaflet-pane, .leaflet-tile, .leaflet-marker-icon, .leaflet-marker-shadow, .leaflet-tile-container, .leaflet-pane > svg, .leaflet-pane > canvas, .leaflet-zoom-box, .leaflet-image-layer, .leaflet-layer {
  position: absolute;
  left: 0;
  top: 0;
}
.leaflet-popup-pane {
  z-index: 700;
  cursor: pointer;
}
.leaflet-popup {
  position: absolute;
  text-align: center;
  margin-bottom: 70px;
}
.leaflet-popup-content-wrapper, .leaflet-popup-tip {
  background: white;
  color: #333;
  box-shadow: 0 3px 14px rgba(0,0,0,0.4);
}
.leaflet-popup-tip {
  width: 17px;
  height: 17px;
  padding: 1px;
  margin: -10px auto 0;
  pointer-events: auto;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.leaflet-interactive {
  cursor: pointer;
}
.leaflet-zoom-animated {
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
}
.leaflet-popup-content-wrapper {
  padding: 1px;
  text-align: left;
  border-radius: 12px;
}
.leaflet-popup-tip-container {
  width: 40px;
  height: 20px;
  position: absolute;
  left: 50%;
  margin-top: -1px;
  margin-left: -20px;
  overflow: hidden;
  pointer-events: none;
}
.leaflet-container a.leaflet-popup-close-button {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  text-align: center;
  width: 24px;
  height: 24px;
  font: 16px/24px Tahoma, Verdana, sans-serif;
  color: #757575;
  text-decoration: none;
  background: transparent;
}
.leaflet-popup-content {
  margin: 13px 24px 13px 20px;
  line-height: 1.3;
  font-size: 13px;
  font-size: 1.08333em;
  min-height: 1px;
}
.leaflet-container {
  height: 400px;
  overflow: hidden;
}
.leaflet-left{
  left:0;
  top: 0;
}
.leaflet-bottom {
  bottom: 0;
}
.leaflet-right {
  right: 0;
}
.leaflet-container .leaflet-control-attribution {
  background: #fff;
  background: rgba(255, 255, 255, 0.8);
  margin: 0;
  padding: 2px 10px;
}
.leaflet-top, .leaflet-bottom {
  position: absolute;
  z-index: 1000;
  pointer-events: none;
}
.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
  border: 2px solid rgba(0,0,0,0.2);
  background-clip: padding-box;
}
.leaflet-left .leaflet-control {
  margin-left: 10px;
}
.leaflet-top .leaflet-control {
  margin-top: 10px;
}
.leaflet-control {
  position: relative;
  z-index: 800;
  pointer-events: visiblePainted;
  pointer-events: auto;
}
.leaflet-control {
  float: left;
  clear: both;
}
.leaflet-touch .leaflet-bar a:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.leaflet-touch .leaflet-bar a {
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.leaflet-touch .leaflet-control-zoom-in, .leaflet-touch .leaflet-control-zoom-out {
  font-size: 22px;
}
.leaflet-bar a, .leaflet-control-layers-toggle {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: block;
}
.leaflet-bar a {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  width: 26px;
  height: 26px;
  line-height: 26px;
  display: block;
  text-align: center;
  text-decoration: none;
  color: black;
}
.leaflet-control-zoom-in, .leaflet-control-zoom-out {
  font: bold 18px 'Lucida Console', Monaco, monospace;
    font-size: 18px;
    line-height: normal;
  text-indent: 1px;
}
.leaflet-touch .leaflet-bar a {
  line-height: 30px;
}
.leaflet-touch .leaflet-control-zoom-in, .leaflet-touch .leaflet-control-zoom-out {
  font-size: 22px;
}
.leaflet-bar a {
  line-height: 26px;
  text-align: center;
  color: black;
}
.geo-modal{
  text-align: center;
  max-width: 800px;
}
.btn-geo{
  display: block;
  margin: 30px auto 10px;
  text-align: center;
}
.btn-geo button,
button.btn-geo{
  background-color: #0a7c35;
  padding: 10px 15px;
  text-transform: uppercase;
color: var(--white);
background: var(--greenSeyband);
text-align: center;
text-decoration: none;
text-transform: uppercase;
border: none;
margin: 0 15px;
width: 150px;
cursor: pointer;
font-size: 1em;
}
button.btn-geo{
  background: var(--green);
  width: 50px;
  height: 50px;
  margin: 0;
}
.btn-geo button.active{
  padding: 15px 15px;
}
.btn-geo button:disabled{
  background-color: #bbb;
  cursor: not-allowed;
}
.max-85{
  max-width: 85px;
}
.max-60{
  max-width: 60px;
}
.max-150{
  max-width: 150px;
}
.max-100{
  max-width: 100px;
}
.show.hoursreporting input,
.show.hoursreporting select{
  padding: 20px 5px 10px 5px;
}
.show.hoursreporting p{
  z-index: 1;
  padding: 5px;
}
.show.hoursreporting #span-icon{
  padding: 20px 15px 10px 0;
}
.hoursreporting small{
  padding-left: 10px;
  margin-bottom: 0;
  margin-top: 3px;
}
.hours-per-category{
  text-align: right;
  padding-right: 5%;
  margin-top: 25px;
}
.justify-end-center{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.col-width p.col-2{
  max-width: 150px;
}
.col-width p.menge {
  max-width: 90px !important;
min-width: 90px;
}
.col-width p.einheit {
  max-width: 110px !important;
min-width: 110px;
}
.col-width p.preis-stk{
  max-width: 80px;
  min-width: 80px;
}
.col-width p.btn-add{
  max-width: 50px;
}
.col-width p.preis-gesamt{
  max-width: 110px;
}
.col-width p.preis-gesamt span{
  text-align: right;
  padding-right: 10px
}
.col-width p.preis-gesamt input{
  text-align: right;
}
.col-width .col.text-right{
  padding-right: 15px;
}
.col-width p.rabatt{
  max-width: 85px;
}
.notesBillSplit{
  margin-top: 10px;
  margin-bottom: 9px;
  font-weight: bold;
}
 
.border-top{
  border-top: 2px solid black;

}
.generate-multiple-bills{
  text-align: right;
}
.generate-multiple-bills button{
  max-width: 240px;
}
.padding-bottom-0{
  padding-bottom: 0;
}
@media print {
 .margin-bottom-print{
  margin-bottom: 50px;
 }
.no-pake-break{
  break-before: always !important;;
  page-break-inside: avoid !important;;
  
}
  .border-code{
    border: 2px solid black;
    max-width: 7.5cm;
    padding-bottom: 10px;
  }
   .modal-qr-code h3,
   .modal-qr-code h4{
    display: block;
   }
   #code{
    width: 5cm !important;
  height: 5cm !important;
   }
   .max-150{
    max-width: 80px;
   }
   .max-85{
    max-width: 60px;
   }
}
 #code{
  margin: 25px auto 50px;
  display: block;
 }
 .max-width-75{
  max-width: 75px;
 }
 .max-width-130{
  max-width: 130px;
 }
 .max-width-180{
  max-width: 180px;
 }
 .max-width-150{
  max-width: 150px
 }
 .max-width-100{
  max-width: 100px
 }
 .max-width-175{
  max-width: 175px;
 }
 .max-width-350{
  max-width: 350px
 }
 .btn-download-regie{
  width: 100%;
  background-color: var(--greenSeyband);
  display: block;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  text-align: center;
 }
 .errorBvIdExists{
  position: fixed;
  bottom: 0px;
  right: 40px;
  height: 100px;
  background-color: #6d1010;
  color: #fff;
  padding: 25px;
  transition: all .3s;
  border-radius: 20px 20px 0 0;
  opacity: 1;
  box-shadow: 0 0 10px #0000005e;
  font-size: 1.2em;
  text-align: center;
  z-index: 999;
 }
 .errorBvIdExists svg{
  margin-bottom: 10px;
  font-size: 1.4em;
 }
 .hideExist.errorBvIdExists{
  height: 0;
  bottom: -50px;
  transition: all .3s;
  opacity: 0;
 }
 .pos-rel{
  position: relative;
 }
 .modal-netto-brutto.modal-dialog{
  width: 400px;
  height: 400px !important;
 }
 .m-t-0 button{
  margin-top: 0;
 
  height: 55px;
 }
 